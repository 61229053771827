<template>
  <div
    v-if="hasValueScheduledPlan()"
    class="dialog"
  >
    <p class="title">
      プランを{{ isUpgrade ? 'アップグレード' : '変更' }}
    </p>

    <p
      v-if="user?.isBilateral"
      class="message"
    >
      お支払い方法を選択してください
    </p>

    <ul class="list">
      <li class="item">
        {{ isUpgrade ? 'プラン変更完了後、すぐにサービスをご利用いただけます' : 'プラン変更完了後、翌月からこちらのプランをご利用いただけます。' }}
      </li>
      <li
        v-if="user?.isBilateral"
        class="item"
      >
        支払い方法は以下のいずれかをご利用いただけます
      </li>
      <li class="item">
        クレジット決済（契約日に決済）
      </li>
      <li
        v-if="user?.isBilateral"
        class="item"
      >
        請求書（対象月の前月1日までに振込）
      </li>
      <li class="item">
        自己都合や解約漏れ等、入金後のキャンセルのご返金は対応は致しかねます。
      </li>
    </ul>

    <div class="actions">
      <button
        v-if="isNewOrBilateralUser"
        class="btn"
        @click="handleCreateSubscription"
      >
        クレジットカードでお支払い
      </button>
      <button
        v-if="isNewOrBilateralUser"
        class="btn"
        @click="redirectToLink"
      >
        その他お支払い
      </button>
      <button
        v-if="!user?.isBilateral && user?.myRendererStatus === MY_RENDERER_STATUS.subscribed.id"
        class="btn"
        @click="isNewOrBilateralUser ? handleCreateSubscription() : handleSunbscriptionChange()"
      >
        {{ isUpgrade ? 'アップグレード' : 'プラン変更' }}を完了する
      </button>
    </div>
  </div>
  <div
    v-else
    class="dialog"
  >
    <p class="title">
      すでに変更が予約されています
    </p>
    <p
      class="message"
    >
      変更したい場合は、カスタマーサポートにお問い合わせください。
    </p>
  </div>
</template>

<script setup lang="ts">
import _ from 'lodash'
import { MY_RENDERER_STATUS } from '~/constants/account-status'
import { isHigherPlan, isTrialPlan as isTrialPlanFn } from '~/utils/plan'

const { id } = defineProps({
  id: {
    type: String,
    required: true,
  },
})

const { setPopup } = usePopup()

const dialogStore = useDialogStore()
const { closeDialog, selectedPlanId } = dialogStore

const accountStore = useAccountStore()
const user = computed(() => accountStore.user)
const isNewOrBilateralUser = computed(() => {
  if (user.value?.isBilateral) { return true }
  return isTrialPlanFn(user.value?.planId)
})
const isUpgrade = isHigherPlan({ selectedPlanId, prevPlanId: user.value?.planId })

function hasValueScheduledPlan() {
  return !user.value!.scheduledPlanId
}

async function handleCreateSubscription() {
  if (!_.isUndefined(selectedPlanId)) {
    const result = await $fetch('/api/stripe/subscriptions/', {
      method: 'POST',
      body: { selectedPlanId },
    })

    if (result.status === 200) {
      if (result.sessionUrl) {
        window.location.href = result.sessionUrl
      } else {
        setPopup('更新が完了しました。', 'green')
        await accountStore.fetchUser()
        closeDialog(id)
      }
    } else if (result.status === 500) {
      setPopup('更新できませんでした。カスタマーサポートへご連絡してください。', 'red')
    }
  } else {
    setPopup('更新できませんでした。このページを再読み込みして、もう一度変更してください', 'red')
  }
}

async function handleSunbscriptionChange() {
  const isTrialPlan = isTrialPlanFn(selectedPlanId)
  const method = isTrialPlan ? 'PATCH' : isNewOrBilateralUser.value && user.value?.myRendererStatus === MY_RENDERER_STATUS.unsubscribed.id ? 'POST' : 'PATCH'
  const url = isTrialPlan ? '/api/stripe/unsubscribe/' : '/api/stripe/subscriptions/'

  const body = !isTrialPlan
    ? { selectedPlanId }
    : undefined

  const result = await $fetch(url, { method, body })

  if (result.status === 200) {
    setPopup(result.message, 'green')
    await accountStore.fetchUser()
    closeDialog(id)
  } else {
    setPopup('更新できませんでした。カスタマーサポートへご連絡してください。', 'red')
  }
}

const redirectToLink = () => {
  window.open(
    'https://docs.google.com/forms/d/e/1FAIpQLSfwFX6ww3Tq6sEA4n9tQV6TKYwnZaxz4t4KFj2coUN9TFQbpQ/viewform',
    '_blank',
  )
}
</script>

<style lang="scss" scoped>
.dialog {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 100%;
  min-width: 345px;
  max-width: 442px;
  padding: 1.5rem;
  border-radius: 0.5rem;
  background-color: rgba(var(--v-theme-background));

  > .title {
    font-size: 1rem;
    font-weight: bold;
  }

  > .message {
    font-size: 0.875rem;
  }

  > .list {
    font-size: 0.75rem;
    font-weight: 400;
  }
}

.dialog > .list {
  > .item {
    list-style: inside;
  }
}

.dialog > .actions {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  > .btn {
    font-weight: bold;
    font-size: 0.875rem;
    width: 100%;
    padding: 1.25rem 0;
    border-radius: 0.25rem;
    background-color: rgba(var(--v-theme-primary-light));
  }
}
</style>
